<h3 class="text-sm font-semibold leading-5 text-gray-300">Discover</h3>

<ul class="mt-4 space-y-3 font-semibold text-gray-200">
	<li>
		<a routerLink="/courses" class="hover:underline hover:decoration-gray-100">
			Courses
		</a>
	</li>

	<li>
		<a routerLink="/universities" class="hover:underline hover:decoration-gray-100">
			Universities
		</a>
	</li>

	<li>
		<a routerLink="/open-days" class="hover:underline hover:decoration-gray-100">
			Open Days
		</a>
	</li>

	<li>
		<a
			routerLink="/personal-statement-examples"
			class="hover:underline hover:decoration-gray-100"
		>
			Statement Examples
		</a>
	</li>

	<li>
		<a routerLink="/rankings" class="hover:underline hover:decoration-gray-100">
			University Rankings
		</a>
	</li>

	<li>
		<a routerLink="/advice" class="hover:underline hover:decoration-gray-100">
			Student Advice
		</a>
	</li>

	<li>
		<a
			routerLink="/degree-quiz/quick-quiz"
			class="hover:underline hover:decoration-gray-100"
		>
			Quick Degree Quiz
		</a>
	</li>

	<li>
		<a
			routerLink="/degree-quiz/full-quiz"
			class="hover:underline hover:decoration-gray-100"
		>
			Full Degree Quiz
		</a>
	</li>
</ul>
