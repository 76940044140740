import {
	ApplicationConfig,
	importProvidersFrom,
	isDevMode,
	provideZoneChangeDetection,
} from '@angular/core';
import {
	provideRouter,
	withComponentInputBinding,
	withInMemoryScrolling,
	InMemoryScrollingFeature,
	InMemoryScrollingOptions,
} from '@angular/router';
import { APP_ROUTES } from './app.routes';
import {
	provideClientHydration,
	withEventReplay,
	withHttpTransferCacheOptions,
} from '@angular/platform-browser';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import {
	_STATE_AUTHENTICATION_FEATURE_KEY,
	authenticationReducer,
	authInterceptor,
	StateAuthEffects,
} from '@uc/shared/authentication';

import { environment } from './../environments/environment';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha-2';
import { JsonLdSchemaService } from '@uc/web/core';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { provideEffects } from '@ngrx/effects';
import {
	StatePromoSlotsEffect,
	StateUniversityEventsEffect,
	StateRecommendedUniversitiesEffects,
	StateClientsDropdownListEffects,
	StatePersonalStatementKeywordsEffects,
	StateUserDetailsEffect,
	StatePageDataEffects,
	StateFormOptionsEffects,
	_STATE_CLIENTS_DROPDOWN_LIST_FEATURE_KEY,
	_STATE_FORM_OPTIONS_FEATURE_KEY,
	_STATE_PAGE_DATA_FEATURE_KEY,
	_STATE_PERSONAL_STATEMENT_KEYWORDS_FEATURE_KEY,
	_STATE_PROMO_SLOTS_FEATURE_KEY,
	_STATE_RECOMMENDED_UNIVERSITIES_FEATURE_KEY,
	_STATE_UNIVERSITY_EVENTS,
	_STATE_USER_DETAILS_FEATURE_KEY,
	formOptionsReducer,
	pageDataReducer,
	personalStatementKeywordsReducer,
	promoSlotsReducer,
	recommendedUniversitiesReducer,
	stateClientsDropdownListReducer,
	universityEventsReducer,
	userDetailsReducer,
} from '@uc/web/shared/data-access';
import {
	_STATE_ARTICLES_CAROUSEL_FEATURE_KEY,
	articlesCarouselReducer,
	StateArticlesCarouselEffect,
} from '@uc/web/shared/feature/feature-articles-carousel';
import {
	_FEATURED_UNIVERSITY_FEATURE_KEY,
	featureUniversityReducer,
	StateFeaturedUniversityEffects,
} from '@uc/web/shared/feature/feature-featured-university';
import {
	_STATE_NAVIGATION_FEATURE_KEY,
	navigationReducer,
	StateNavigationEffects,
} from '@uc/web/shared/feature/feature-navigation';
import {
	_STATE_PRIME_PROVIDERS_FEATURE_KEY,
	primeProvidersReducer,
	StatePrimeProvidersEffects,
} from '@uc/web/shared/feature/feature-prime-providers';
import {
	_STATE_STICKY_LEAD_BAR_FEATURE_KEY,
	StateStickyLeadBarEffects,
	stickyLeadBarReducer,
} from '@web/shared/feature/feature-sticky-lead-bar';
import { provideStore } from '@ngrx/store';
import { graphqlProvider } from './graphql.config';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideToastr } from 'ngx-toastr';
import { NotificationComponent } from '@uc/shared/ui';

const scrollConfig: InMemoryScrollingOptions = {
	scrollPositionRestoration: 'top',
	anchorScrolling: 'enabled',
};

const inMemoryScrollingFeature: InMemoryScrollingFeature =
	withInMemoryScrolling(scrollConfig);

export const appConfig: ApplicationConfig = {
	providers: [
		provideStore({
			[_STATE_AUTHENTICATION_FEATURE_KEY]: authenticationReducer,
			[_STATE_PROMO_SLOTS_FEATURE_KEY]: promoSlotsReducer,
			[_STATE_UNIVERSITY_EVENTS]: universityEventsReducer,
			[_STATE_CLIENTS_DROPDOWN_LIST_FEATURE_KEY]: stateClientsDropdownListReducer,
			[_STATE_RECOMMENDED_UNIVERSITIES_FEATURE_KEY]: recommendedUniversitiesReducer,
			[_STATE_STICKY_LEAD_BAR_FEATURE_KEY]: stickyLeadBarReducer,
			[_STATE_PERSONAL_STATEMENT_KEYWORDS_FEATURE_KEY]:
				personalStatementKeywordsReducer,
			[_STATE_USER_DETAILS_FEATURE_KEY]: userDetailsReducer,
			[_STATE_PAGE_DATA_FEATURE_KEY]: pageDataReducer,
			[_STATE_FORM_OPTIONS_FEATURE_KEY]: formOptionsReducer,
			[_STATE_PRIME_PROVIDERS_FEATURE_KEY]: primeProvidersReducer,
			[_STATE_ARTICLES_CAROUSEL_FEATURE_KEY]: articlesCarouselReducer,
			[_FEATURED_UNIVERSITY_FEATURE_KEY]: featureUniversityReducer,
			[_STATE_NAVIGATION_FEATURE_KEY]: navigationReducer,
		}),
		provideEffects([
			StateAuthEffects,
			StatePromoSlotsEffect,
			StateUniversityEventsEffect,
			StateRecommendedUniversitiesEffects,
			StateClientsDropdownListEffects,
			StateStickyLeadBarEffects,
			StatePersonalStatementKeywordsEffects,
			StateUserDetailsEffect,
			StatePageDataEffects,
			StateFormOptionsEffects,
			StatePrimeProvidersEffects,
			StateArticlesCarouselEffect,
			StateFeaturedUniversityEffects,
			StateNavigationEffects,
		]),
		provideStoreDevtools({
			maxAge: 25, // Retains last 25 states
			logOnly: !isDevMode(), // Restrict extension to log-only mode
			autoPause: true, // Pauses recording actions and state changes when the extension window is not open
			trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
			traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
			connectInZone: true, // If set to true, the connection is established within the Angular zone
		}),
		provideClientHydration(
			withEventReplay(),
			withHttpTransferCacheOptions({
				includePostRequests: true,
			}),
		),
		provideZoneChangeDetection({ eventCoalescing: true }),
		provideRouter(APP_ROUTES, withComponentInputBinding(), inMemoryScrollingFeature),
		provideHttpClient(withFetch(), withInterceptors([authInterceptor])),
		graphqlProvider,

		provideAnimations(), // required animations providers
		provideToastr({
			timeOut: 3000,
			progressBar: true,
			progressAnimation: 'increasing',
			closeButton: true,
			toastComponent: NotificationComponent,
		}), // Toastr providers
		{
			provide: 'environment',
			useValue: environment,
		},
		{
			provide: RECAPTCHA_V3_SITE_KEY,
			useValue: environment.recaptcha_public_key,
		},
		importProvidersFrom(RecaptchaV3Module),
		JsonLdSchemaService,
	],
};
