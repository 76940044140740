import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { LinkedinFooterSvgComponent, TiktokSvgComponent } from '@uc/shared/svg';
import { InstagramOutlineSvgComponent } from '@uc/shared/svg';

@Component({
	selector: 'uc-secondary-footer',
	imports: [
		RouterLink,
		TiktokSvgComponent,
		LinkedinFooterSvgComponent,
		InstagramOutlineSvgComponent,
	],
	templateUrl: './secondary-footer.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SecondaryFooterComponent {
	currentYear = new Date().getFullYear();
}
