import { Route } from '@angular/router';
import { inAppBrowserGuard } from '@uc/web/core';

export const APP_ROUTES: Route[] = [
	{
		path: '',
		loadChildren: () =>
			import('../../../../libs/web/pages/src').then((routes) => routes.WEB_ROUTES),
		canActivate: [inAppBrowserGuard],
	},
];
