import {
	BrowserModule,
	provideClientHydration,
	withHttpTransferCacheOptions,
} from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

import { AppComponent } from './app.component';
import {
	HTTP_INTERCEPTORS,
	HttpClientModule,
	provideHttpClient,
	withFetch,
} from '@angular/common/http';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { GraphQLModule } from './graphql.module';

import { environment } from './../environments/environment';
import { inAppBrowserGuard, JsonLdSchemaModule } from '@uc/web/core';
import { WebSharedFeatureNavigationModule } from '@uc/web/shared/feature/feature-navigation';
import {
	StateClientsDropdownListEffects,
	StatePromoSlotsEffect,
	_STATE_CLIENTS_DROPDOWN_LIST_FEATURE_KEY,
	StateRecommendedUniversitiesEffects,
	_STATE_PROMO_SLOTS_FEATURE_KEY,
	_STATE_RECOMMENDED_UNIVERSITIES_FEATURE_KEY,
	promoSlotsReducer,
	StateUniversityEventsEffect,
	_STATE_UNIVERSITY_EVENTS,
	universityEventsReducer,
	stateClientsDropdownListReducer,
	recommendedUniversitiesReducer,
	StatePersonalStatementKeywordsEffects,
	_STATE_PERSONAL_STATEMENT_KEYWORDS_FEATURE_KEY,
	personalStatementKeywordsReducer,
	_STATE_USER_DETAILS_FEATURE_KEY,
	userDetailsReducer,
	StateUserDetailsEffect,
	_STATE_PAGE_DATA_FEATURE_KEY,
	pageDataReducer,
	StatePageDataEffects,
	_STATE_FORM_OPTIONS_FEATURE_KEY,
	formOptionsReducer,
	StateFormOptionsEffects,
} from '@uc/web/shared/data-access';
import {
	AuthInterceptor,
	StateAuthEffects,
	_STATE_AUTHENTICATION_FEATURE_KEY,
	authenticationReducer,
} from '@uc/shared/authentication';
import { FooterComponent } from '@uc/web/shared/feature/feature-footer';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { NotificationComponent } from '@uc/shared/ui';
import {
	StateStickyLeadBarEffects,
	_STATE_STICKY_LEAD_BAR_FEATURE_KEY,
	stickyLeadBarReducer,
} from '@web/shared/feature/feature-sticky-lead-bar';
import {
	_STATE_PRIME_PROVIDERS_FEATURE_KEY,
	primeProvidersReducer,
	StatePrimeProvidersEffects,
} from '@uc/web/shared/feature/feature-prime-providers';

@NgModule({
	declarations: [AppComponent],
	imports: [
		FooterComponent,
		GraphQLModule,
		HttpClientModule,
		BrowserAnimationsModule,
		WebSharedFeatureNavigationModule,
		JsonLdSchemaModule,
		StoreModule.forRoot(
			{
				[_STATE_AUTHENTICATION_FEATURE_KEY]: authenticationReducer,
				[_STATE_PROMO_SLOTS_FEATURE_KEY]: promoSlotsReducer,
				[_STATE_UNIVERSITY_EVENTS]: universityEventsReducer,
				[_STATE_CLIENTS_DROPDOWN_LIST_FEATURE_KEY]:
					stateClientsDropdownListReducer,
				[_STATE_RECOMMENDED_UNIVERSITIES_FEATURE_KEY]:
					recommendedUniversitiesReducer,
				[_STATE_STICKY_LEAD_BAR_FEATURE_KEY]: stickyLeadBarReducer,
				[_STATE_PERSONAL_STATEMENT_KEYWORDS_FEATURE_KEY]:
					personalStatementKeywordsReducer,
				[_STATE_USER_DETAILS_FEATURE_KEY]: userDetailsReducer,
				[_STATE_PAGE_DATA_FEATURE_KEY]: pageDataReducer,
				[_STATE_FORM_OPTIONS_FEATURE_KEY]: formOptionsReducer,
				[_STATE_PRIME_PROVIDERS_FEATURE_KEY]: primeProvidersReducer,
			},
			{
				runtimeChecks: {
					strictStateImmutability: false,
					strictActionImmutability: false,
				},
			},
		),
		EffectsModule.forRoot([
			StateAuthEffects,
			StatePromoSlotsEffect,
			StateUniversityEventsEffect,
			StateRecommendedUniversitiesEffects,
			StateClientsDropdownListEffects,
			StateStickyLeadBarEffects,
			StatePersonalStatementKeywordsEffects,
			StateUserDetailsEffect,
			StatePageDataEffects,
			StateFormOptionsEffects,
			StatePrimeProvidersEffects,
		]),
		ToastrModule.forRoot({
			timeOut: 3000,
			progressBar: true,
			progressAnimation: 'increasing',
			closeButton: true,
			toastComponent: NotificationComponent,
		}), // ToastrModule added
		StoreDevtoolsModule.instrument({
			maxAge: 25, // Retains last 25 states
			logOnly: false, // Restrict extension to log-only mode
			autoPause: true, // Pauses recording actions and state changes when the extension window is not open
			connectInZone: true,
		}),
		RouterModule.forRoot(
			[
				{
					path: '',
					loadChildren: () =>
						import('../../../../libs/web/pages/src').then(
							(module) => module.WebPagesModule,
						),
					canActivate: [inAppBrowserGuard],
				},
			],
			{
				bindToComponentInputs: true,
				scrollPositionRestoration: 'enabled',
				initialNavigation: 'enabledBlocking',
			},
		),
		RecaptchaV3Module,
	],
	exports: [],
	providers: [
		provideHttpClient(withFetch()),
		provideClientHydration(
			withHttpTransferCacheOptions({
				includePostRequests: true,
			}),
		),
		HttpClientModule,
		BrowserModule,
		{
			provide: 'environment',
			useValue: environment,
		},
		{
			provide: RECAPTCHA_V3_SITE_KEY,
			useValue: environment.recaptcha_public_key,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
