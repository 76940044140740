import { ApplicationConfig, PLATFORM_ID, inject } from '@angular/core';
import { APOLLO_OPTIONS, Apollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { ApolloClientOptions, DefaultOptions, InMemoryCache } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { environment } from '../environments/environment';
import { isPlatformServer } from '@angular/common';

const defaultOptions: DefaultOptions = {
	watchQuery: {
		fetchPolicy: 'no-cache',
	},
	query: {
		fetchPolicy: 'no-cache',
	},
	mutate: {
		fetchPolicy: 'no-cache',
	},
};

function resolveURI() {
	const platformId = inject(PLATFORM_ID);
	const domain = environment.graphql_url;
	const local = 'http://127.0.0.1/api/graphql';
	const baseUrl = isPlatformServer(platformId) && !environment.devMode ? local : domain;

	return baseUrl;
}

export function apolloOptionsFactory(): ApolloClientOptions<any> {
	const httpLink = inject(HttpLink);

	const errorLink = onError(({ graphQLErrors, networkError, response }) => {
		console.error('networkError', networkError);
		if (graphQLErrors) {
			graphQLErrors.map(({ message, locations, path }) =>
				console.error(
					`[GRAPHQL ERROR]: Message: ${message}, Location: ${locations}, Path: ${path}`,
				),
			);
		}

		if (networkError) {
			const error = JSON.stringify((networkError as any)?.error);
			console.error(`[ERROR MESSAGE]: ${error}`);
		}

		if (response) {
			console.error(`[RESPONSE]: ${JSON.stringify(response)}`);
		}
	});
	return {
		link: errorLink.concat(httpLink.create({ uri: resolveURI() })),
		cache: new InMemoryCache({ addTypename: false }),
		defaultOptions: defaultOptions,
	};
}

export const graphqlProvider: ApplicationConfig['providers'] = [
	Apollo,
	{
		provide: APOLLO_OPTIONS,
		useFactory: apolloOptionsFactory,
	},
];
