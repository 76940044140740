import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { Subscription } from 'rxjs';
import { DataLayerService } from '@uc/web/core';
import { isPlatformBrowser, NgClass } from '@angular/common';
import {
	CanonicalService,
	Environment,
	EnvironmentApiService,
	BotsIndexService,
} from '@uc/utilities';
import { PreviousPageBtnService } from '@web/shared/ui';
import { CustomCookieService, Role, updateAuthState } from '@uc/shared/authentication';
import { register } from 'swiper/element/bundle';
import {
	fetchClientDetails,
	fetchStudentDetails,
	resetClientDetails,
	resetStudentDetails,
} from '@uc/web/shared/data-access';
import { Store } from '@ngrx/store';
import { FooterComponent } from '@uc/web/shared/feature/feature-footer';
import { NavigationComponent } from '@uc/web/shared/feature/feature-navigation';

declare let hj: (state: string, url: string) => void;
declare let maze: (window: Window, document: Document, url: string, id: string) => void;

@Component({
	selector: 'uc-root',
	templateUrl: './app.component.html',
	imports: [FooterComponent, NavigationComponent, RouterModule, NgClass],
	providers: [EnvironmentApiService],
})
export class AppComponent implements OnInit, OnDestroy {
	showHeaderFooter = true;
	showFooter = true;
	scrollDisabled = false;

	private _hidePageUrls = [
		'login',
		'password',
		'register-interest',
		'register',
		'order',
		'select',
		'request',
		'degree-quiz/quick-quiz',
		'degree-quiz/full-quiz',
	];

	private _hideFooterUrls = ['404'];
	private _rootUrl = 'https://universitycompare.com';
	private _routerSub!: Subscription;

	constructor(
		@Inject(PLATFORM_ID) private _platformId: string,
		@Inject('environment') private environment: Environment,
		private _router: Router,
		private _store: Store,
		private _canonicalSrv: CanonicalService,
		private _customCookieSrv: CustomCookieService,
		private _dataLayerSrv: DataLayerService,
		private _prevPageBtnSrv: PreviousPageBtnService,
		private _botsIndexSrv: BotsIndexService,
	) {
		register();
	}

	ngOnInit(): void {
		this._processAuthentication();

		this._routerSub = this._router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				const url = this._router.url;
				const fullUrl = `${this._rootUrl}${url}`;

				//only turn indexing on / off in production, otherwise keep turned off
				if (this.environment.production) {
					this._botsIndexSrv.updateRobotsMetaTag(url);
				}

				// save previous url
				const trackPath = this._prevPageBtnSrv.determineIfPathShouldBeTracked(
					event.url,
				);

				if (trackPath) {
					this._prevPageBtnSrv.setPreviousPath(event.url);
				}

				this._canonicalSrv.updateCanonicalUrl(fullUrl);

				this._executeAnalytics(url, fullUrl);

				this._hidePageUrls.every((page: string) => {
					this.showHeaderFooter = !url.includes(page);
					return this.showHeaderFooter;
				});

				this._hideFooterUrls.every((page: string) => {
					this.showFooter = !url.includes(page);
					return this.showFooter;
				});
			}
		});
	}

	ngOnDestroy(): void {
		if (this._routerSub) this._routerSub.unsubscribe();
	}

	private _processAuthentication() {
		const loginData = this._customCookieSrv.getAll();

		if (loginData?.token) {
			const authData = {
				role: loginData.role,
				userId: loginData.user_id,
			};

			if (loginData?.role === Role.STUDENT) {
				this._store.dispatch(fetchStudentDetails());
				this._store.dispatch(updateAuthState({ authData }));
				this._store.dispatch(resetClientDetails());
			} else if (loginData.role === Role.CLIENT) {
				this._store.dispatch(fetchClientDetails());
				this._store.dispatch(updateAuthState({ authData }));
				this._store.dispatch(resetStudentDetails());
			}
		}
	}

	private _executeAnalytics(url: string, fullUrl: string): void {
		if (isPlatformBrowser(this._platformId)) {
			if (this.environment.production === true) {
				const userId = this._customCookieSrv.get('user_id');
				const dataLayer = {
					loginStatus: userId ? 'yes' : 'no',
					userId: userId ? userId : null,
					event: 'pageMetaData',
				};
				this._dataLayerSrv.push(dataLayer);

				hj('stateChange', fullUrl);
			}

			if (!this.environment.production) {
				maze(
					window,
					document,
					'https://snippet.maze.co/maze-universal-loader.js',
					'5912772f-d44a-4792-846b-a21093213246',
				);
			}
		}
	}
}
