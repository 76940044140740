<div class="relative grid gap-8">
	<img
		[ngSrc]="'/assets/img/unicompare_white_logo_1.png' | cdn"
		width="219"
		height="41"
		alt="Uni Compare Logo"
	/>

	<p class="pr-6 text-gray-300 text-md">
		We believe everyone should have the choice to find the right course at the right
		university.
	</p>

	<a
		(click)="onDownloadAppClick()"
		href="https://apps.apple.com/gb/app/uni-compare/id1100270774"
		target="_blank"
		rel="noopener"
		title="Download our iOS app"
		class="max-w-max"
		data-cy="footer-download-ios-app"
	>
		<span class="sr-only">Download iOS app</span>
		<img
			[ngSrc]="'/assets/img/download-ios.svg' | cdn"
			alt="Apple Store Logo"
			width="120"
			height="40"
		/>
	</a>
</div>
